const groq = require('groq')

//
// === Pages ===
//

const asset = groq`{
    'imageId': image.asset->_id,
    'url': image.asset->url,
    altText
}
`



const SEOQuery = groq`
  _type,
  metaKeywords,
  metaDescription,
  metaTitle,
  openGraphDescription,
  'openImage': openImage.asset->url,
  openTitle,
  twitterTitle,
  twitterDescription,
  'twitterImage': twitterImage.asset->url
`

const spiceQuery = groq`
  _key,
  'title': content.main.title,
  'productTitle': content.main.productTitle,
  'description': content.main.text,
  'fact': content.main.funFact,
  'image': content.main.image.image.asset->_id,
  'imageAlt': content.main.image.altText,
  'stats': content.main.stats,
  'podImage': content.main.podImage ${asset},
  'secondaryImage': content.main.secondaryImage.image.asset->_id,
  'secondaryImageAlt': content.main.secondaryImage.altText,
  'recipeNote': content.main.recipeNote,
  'recipeUrl': content.main.recipeUrl,
  'recipeCta': content.main.recipeCta,
  'linkCtaOverride': content.main.linkedCtaOverride,
  'product': content.main.productReference-> {
    _type,
    'title': content.main.title,
    'slug': content.main.slug
  }
`

const chefCard = groq`
  'name': content.name,
  'image': content.profileImage ${asset}
`

const variantCard = groq`
  'variants': content.shopify.variants[]-> {
    'variantTitle': content.shopify.variantTitle,
    'variantId': content.shopify.variantId,
    'sku': content.shopify.sku,
    'variantPrice': content.shopify.price
  }
`

const miniProductCard = groq`
  'title': content.main.title,
  'customTitle': content.main.customTitle,
  'slug': content.main.slug.current,
  'imageId': content.main.mainImage.asset->_id
`


const productCard = groq`
  ${miniProductCard},
  'serving': content.main.servingSize,
  'dietary': content.main.dietary,
  'composition': content.main.composition,
  'form': content.main.form,
  'badgeText': content.main.badgeText,
  'productType': content.main.productType,
  'clpHover': content.main.clpHoveImage {
    'imageId': asset->_id,
    altText
  },
  'image': content.main.mainImage {
    'imageId': asset->_id,
    altText
  },
  ${variantCard},
  'linkedProducts': content.main.linkedProducts[] {
    ...,
    product-> {
      'title': content.main.title,
      ${variantCard}
    }
  },
  'description': content.main.productDescription,
  'chef': content.main.chef-> {
    ${chefCard}
  }
`

const textQuery = groq`
  markDefs[]{
    ...,
    _type == "internalLink" => {
      ...,
      reference->
    }
  },
  _type == 'imageModule' => {
    ...,
    'image': ${asset}
  },
  _type == 'articleImages' => {
    ...,
    image[] {
      ...,
      'image': ${asset}
    }
  },
  _type == 'articleProduct' => {
    ...,
    'product': product-> {
      ${miniProductCard}
    }
  }
`

const moduleQuery = groq`
  _type == 'productGrid' => {
    ...,
    'showAll': showAllLink-> {
      'slug': content.main.slug.current
    },
    products[] {
      ...,
      _type == 'standardCTA' => {
        ...,
        cta[] {
          ...,
          ${textQuery}
        }
      },
      _type == 'reference' => {
        ...,
        'product': *[_type == 'product' && _id == ^ ._ref][0] {
          ...,
          ${productCard}
        }
      }
    }
  },
  _type == 'productScroll' => {
    ...,
    products[] {
      ...,
      _type == 'reference' => {
        ...,
        'product': *[_type == 'product' && _id == ^ ._ref][0] {
          ...,
          ${productCard}
        }
      }
    }
  },
  _type == 'includedRecipes' => {
    recipes[]-> {
      ...,
      'title': content.main.title,
      'slug': content.main.slug.current,
      'chef': content.main.chef->content.name
    }
  },
  _type == 'compareChart' => {
    ...,
    compare-> {
      ...,
      'image': ${asset}
    }
  },

  _type == 'ctaBlock' => {
    ...,
    'image': image ${asset},
    text[] {
      ...,
      ${textQuery}
    }
  },
  _type == 'pressModule' => {
    ...,
    press[]-> {
      ...,
      'imageId': logo.asset->_id,
    }
  },
  _type == 'twoPodColumns' => {
    cols[] {
      text[] {
        ...,
        ${textQuery}
      },
      image ${asset},
      ctaLink[] {
        title,
        _type == 'internalLink' => {
          link-> {
            ...,
            _type,
            'slug': content.main.slug.current
          }
        },
      }
    }
  },
  _type == 'shopAllCardsMarquee' => {
    title,
    ctaLink[] {
      title,
      _type == 'internalLink' => {
        link-> {
          ...,
          _type,
          'slug': content.main.slug.current
        }
      },
    },
    images[] {
      ...,
      'image': ${asset}
    },
  },
  _type == 'marketingImageText' => {
    alignment,
    text[] {
      ...,
      ${textQuery}
    },
    image ${asset}
  },
  _type == 'badgesHero' => {
    badges,
    title,
    image ${asset},
    mobileImage ${asset}
  },
  _type == 'imageModule' => {
    ...,
    'image': ${asset}
  },
  _type == 'aboutEnlightenment' => {
    ...,
    'image': image ${asset}
  },
  _type == 'aboutTryAnything' => {
    ...,
    'image': image ${asset}
  },
  _type == 'aboutImageAndText' => {
    ...,
    'image': image ${asset}
  },
  _type == 'aboutTextImageArray' => {
    ...,
    items[] {
      ...,
      'image': image ${asset}
    }
  },
  _type == 'includedSpices' => {
    ...,
    spices[]-> {
      ${spiceQuery}
    }
  },
  _type == 'productRow' => {
    ...,
    products[]-> {
      ...,
      ${productCard}
    }
  },
  _type == 'spiceStats' => {
    ...,
    spice-> {
      ${spiceQuery}
    }
  },
  _type == 'chefModule' => {
    ...,
    chef-> {
      ...,
      content {
        ...,
        qAndAImage {
          ...,
          image {
            asset-> {
              _id
            }
          }
        },
        chefSlideshow {
          images[] {
            image {
              ...,
              asset-> {
                _id
              }
            }
          }
        },
        mainFoodImage {
          ...,
          image {
            asset-> {
              _id
            }
          }
        }
      },

      'externalLinks': content.main.externalLinks,
      'chefSlideshow': content.chefSlideshow.images[] {
        'image': ${asset}
      },
      'image': content.profileImage.image.asset->_id,
      'name': content.name,
      'faqs': content.faqs[] {
        ...,
        text[] {
          ...,
          ${textQuery}
        }
      },
      'quickInformation': content.quickInformation,
      'qAndAImage': content.qAndAImage.image.asset->_id,
      'bio': content.text[] {
        ...,
        ${textQuery}
      }
    }
  },
  _type == 'faqs' => {
    ...,
    faqs[] {
      ...,
      faqs[] {
        ...,
        text[] {
          ...,
          ${textQuery}
        }
      }
    }
  },
  _type == 'nestedProductHero' => {
    ...,
    _type,
    'product': product-> {
      ${productCard},
      'heroImages': content.main.heroImages[] {
        'image': ${asset}
      },
      content {
        ...,
        main {
          ...,
          linkedProducts[] {
            ...,
            product->
          },
          mainImage {
            asset-> {
              _id
            }
          }
        }
      }
    }
  },
  _type == 'doubleCta' => {
    ...,
    ctas[] {
      ...,
      'image': image ${asset},
      'linked': linked->content.main
    }
  },
  _type == 'imageAndText' => {
    'image': image ${asset},
    text[] {
      ...,
      ${textQuery}
    }
  },
  _type == 'expectReference' => {
    whatToExpect-> {
      alignment,
      image ${asset},
      text[] {
        ...,
        ${textQuery}
      },
      experienceIcons[] {
        subTitle,
        _key,
        image ${asset}
      }
    }
  },
  _type == 'productCarousel' => {
    ...,
    items[]-> {
      ...,
      ${productCard},
      'chef': content.main.chef-> {
        ${chefCard}
      }
    }
  },
  _type == 'reference' => {
    ...,
    'deck': *[_type == 'deck' && _id == ^ ._ref][0] {
      ...,
      'image': image ${asset},
      productReference-> {
        'productType': content.main.productType,
        'chef': content.main.chef-> {
          ${chefCard}
        },
        'description': content.main.productDescription,
        'slug': content.main.slug.current,
        'linkedProducts': content.main.linkedProducts[] {
          quantity,
          'productType': product->content.main.productType
        }
      }
    }
  },
  _type == 'reduceWaste' => {
    ...,
    'image': image ${asset},
    ctaLink-> {
      'slug': content.main.slug.current,
      _type
    }
  },
  _type == 'shoppableHero' => {
    ...,
    slides[] {
      ...,
      text,
      _key,
      'image': image ${asset}
    },
    productImages[] {
      'image': ${asset}
    }
  },
  _type == 'twoColumnModule' => {
    ...,
    cols[] {
      ...,
      _type == 'standardText' => {
        ...,
        ctaLink[] {
          ...,
          _type == 'internalLink' => {
            link-> {
              _type,
              'slug': content.main.slug.current
            }
          },
          _type == 'file' => {
            ...,
            asset->
          }
        },
        text[] {
          ...,
          ${textQuery}
        }
      },
      _type == 'imageAndBadge' => {
        ...,
        'image': imageModule.image.asset->_id,
        'imageUrl': imageModule.image.asset->url
      }
    }
  }
`

const pageQuery = groq`
  'title': content.main.title,
  'slug': content.main.slug.current,
  'meta': content.meta {
    ${SEOQuery}
  },
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  },
  content,
`

const collectionQuery = groq`
  'title': content.main.title,
  'slug': content.main.slug.current,
  'filters': content.main.filters,
  'meta': content.meta {
    ${SEOQuery}
  },
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  },

  'pageModules': content.main.pageModules[] {
    ...,
    ${moduleQuery}
  },
  content,
`

const productQuery = groq`
  ${productCard},
  'meta': content.meta {
    ${SEOQuery}
  },
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  },
  'heroImages': content.main.heroImages[] {
    'image': ${asset}
  },
  content {
    ...,
    main {
      ...,
      linkedProducts[] {
        ...,
        product->
      },
      mainImage {
        asset-> {
          _id
        },
        'imageId': asset->_id,
        'url': asset->url,
        altText
      }
    }
  },
`

const articleCard = `
  'publicationDate': content.main.publicationDate,
  'slug': content.main.slug.current,
  'title': content.main.title,
  'image': content.image.image {
		'imageId': asset->_id
  },
  'tags': content.main.tags-> {
    ...,
  }
`

const siteQuery = `
  "site": {
    "seo": *[_type == "seoSettings"][0]{
      siteTitle,
      metaTitle,
      metaDesc,
      shareTitle,
      shareDesc,
      shareGraphic
    },
		"social": *[_type == "generalSettings"][0]{
      "items": social[]{
        icon,
        url
      }
    },
  }
`

const menuQuery = `
  _id,
  title,
  'handle': slug.current,
  items[] {
    _type == 'externalLink' => {
      _key,
      _type,
      title,
      link
    },
    _type == 'internalLink' => {
      _key,
      _type,
      title,
      'link': {
        '_type': link->_type,
        'slug': link->content.main.slug.current
      }
    },
    _type == 'softLink' => {
      _key,
      title,
      link
    }
  }
`

module.exports.menus = groq`*[_type == "menus"] {
  ${menuQuery}
}`

module.exports.pages = groq`*[_type == "page"] {
  ${pageQuery}
}`

module.exports.settings = groq`*[_type == "siteGlobal"][0] {
  content {
    promo,
    promoActive,
    social[],
    metaInformation,
    instagramText,
    'cart': {
      shippingMessage,
      'cartImage': cartImage ${asset},
      text,
    },
    instagramImages[] {
      'image': ${asset}
    }
  },
  'defaultMeta': content.metaInformation {
    ${SEOQuery}
  }
}`

module.exports.about = groq`*[_type == "about" && content.main.slug.current == "about"][0] {
  ${pageQuery}
}`

module.exports.products = groq`*[_type == "product"] {
  ${productQuery}
}`

module.exports.collections = groq`*[_type == "collection"] {
  ${collectionQuery}
}`


module.exports.articleCards = groq`*[_type == "article"] | order(publicationDate desc)  {
  _key,
  _id,
  ${articleCard}
}`

module.exports.articles = groq`*[_type == "article"] | order(publicationDate desc)  {
  ...,
  'text': content.main.text[] {
    ...,
    ${textQuery},
  },
  'author': content.main.author-> {
    ${chefCard}
  },
  ${articleCard},
  'relatedArticles': *[_type == 'article' && _id != ^._id][0...3] | order(publicationDate desc) {
    ${articleCard}
  }
}`

module.exports.spices = groq`*[_type == "spice"] {
  ...,
  ${spiceQuery}
}`


module.exports.spiceIndex = groq`*[_type == "spiceIndex"][0] {
  ...,
  'image': image ${asset},
  videoFileUrl

}`

module.exports.draftPage = draftId => `*[_type == "page" && _id == "${draftId}"][0]{
  ${pageQuery}
}`

module.exports.pageQuery = pageQuery
module.exports.productQuery = productQuery